
const config = {
    baseURL: window.location.origin,
    imageOSS:"https://oss.qianniangy.net",
    // baseURL: "https://zhfwtest.daoben.tech",
}

if (process.env.NODE_ENV === 'development') {
    config.baseURL = "https://testaa.qianniangy.net" //"https://testbd.qiannianjituan.com/" 
    
}
export default config
